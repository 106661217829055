<template>
  <section class="page-header-with-breadcrumbs bg-half bg-light d-table w-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title">{{props.title}}</h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"
                    v-for="(item, index) in props.breadcrumbs"
                    v-bind:key="index"
                    v-bind:class="{ active: index === (props.breadcrumbs.length - 1) }">
                    <router-link v-bind:to="item.path" v-if="index < (props.breadcrumbs.length - 1)">{{item.label}}</router-link>
                    <span v-else>{{item.label}}</span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      }
    }
  };
</script>